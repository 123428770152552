import React, { useMemo } from 'react';

import withNav from '@/infrastructure/hoc/withNav';
import { PageContainer } from '@/components';
import { Box } from '@mui/material';

import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';

import { LtTabsProps } from '@/components/LtTabs';
import { ContactPageOutlined } from '@mui/icons-material';
import { InternalForm } from './InternalForm';
import { ExternalForm } from './ExternalForm';

enum SCREEN {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

const ContactFormConfigurator = () => {
  const { t } = useAppTranslation();

  const tabs: LtTabsProps = useMemo(
    () => ({
      orientation: 'horizontal',
      tabs: [
        {
          icon: <ContactPageOutlined />,
          iconPosition: 'start' as const,
          value: SCREEN.EXTERNAL,
          label: t('contactFormEdit.external.title'),
          component: (
            <Box py={2}>
              <ExternalForm />
            </Box>
          ),
        },
        {
          icon: <ContactPageOutlined />,
          iconPosition: 'start' as const,
          value: SCREEN.INTERNAL,
          label: t('contactFormEdit.internal.title'),
          component: (
            <Box py={2}>
              <InternalForm />
            </Box>
          ),
        },
      ],
    }),
    [t],
  );

  return (
    <PageContainer
      whiteCardWrapper
      maxWidth='l'
      tabs={tabs}
      helpSidebarConfigKey='help-sidebar-contact-form'
    />
  );
};

export default withNav(ContactFormConfigurator, { tTitle: 'contactFormPageHeader' }, {});
